@charset "utf-8";
:root {
    --main-color:#FD184F;
}
.hidden{
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}
.flex{
	display: flex;
}
.between{
	justify-content: space-between;
}
.around{
	justify-content: space-around;
}
.wrap{
	flex-wrap: wrap;
}
.tac{
	text-align: center;
}
.tar{
	text-align: right;
}
.mt10{
    margin-top:10px !important;
}
/*------------------------------------------------------------------------
1.リセット
------------------------------------------------------------------------*/

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, p, blockquote, table, th, td {
	margin:0;
	padding:0;
}
html, body {
	background:#fff;
	color: #000;
}
body,
form input{
	font:16px/1.5 "メイリオ", "Meiryo", ryumin,Helvetica, "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "ＭＳ Ｐゴシック", sans-serif;
	-webkit-text-size-adjust: 100%;
}
* html body {
	font-size: small;
}
body{
    height: 100%;
}
*:first-child+html body {
	font-size: small;
}
img {
	border: 0;
	vertical-align:top;
}
h1, h2, h3, h4, h5, h6 {
	clear: both;
	font-size: 100%;
	font-weight: normal;
}
ul, dl, ol {
	text-indent:0;
}
ul li {
	list-style:none;
}
address, caption, cite, code, dfn, em, strong, th, var {
	font-style: normal;
	font-weight: normal;
}
sup {
	vertical-align:text-top;
}
sub {
	vertical-align:text-bottom;
}
input, textarea, select {
	font-family:inherit;
	font-size: inherit;
	font-weight:inherit;
}
input, button, textarea, select {
	margin: 0;
	padding: 0;
	background: none;
	border: none;
	border-radius: 0;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
input{
	height: 36px;
}

* html input, * html textarea, * html select {
	font-size: 100%;
}
*:first-child+html+input, *:first-child html+textarea, *:first-child+html select {
	font-size: 100%;
}
table {
	border-collapse:collapse;
	border-spacing:0;
	font-size:inherit;
}
th, td {
	text-align:left;
	vertical-align:top;
}
caption {
	text-align:left;
}
pre, code, kbd, samp, tt {
	font-family: monospace;
}
* html code, * html kbd, * html samp, * html tt {
	font-size: 100%;
	line-height: 100%;
}
*:first-child+html pre, *:first-child html+code, *:first-child html+kbd, *:first-child+html+samp, *:first-child+html tt {
	font-size: 108%;
	line-height: 100%;
}
input, button, textarea, select {
	margin: 0;
	padding: 0;
	background: none;
	border: none;
	border-radius: 0;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
a:link {
	color:#000;
	text-decoration: none;
}
a:visited {
	color:#000;
	text-decoration: none;
}
a:active {
	color:#000;
	text-decoration: none;
}
a:hover {
	text-decoration: none;
    color:#c30;
}
a {
	outline : 0;
}
a img{
	border : none;
}
.hide{
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}
.hidden{
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}
.red{
	color:#ab2727;
}