:root {
  --main-color: #fd184f;
}

.hidden {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.flex {
  display: flex;
}

.between {
  justify-content: space-between;
}

.around {
  justify-content: space-around;
}

.wrap {
  flex-wrap: wrap;
}

.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

.mt10 {
  margin-top: 10px !important;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, p, blockquote, table, th, td {
  margin: 0;
  padding: 0;
}

html, body {
  color: #000;
  background: #fff;
}

body, form input {
  -webkit-text-size-adjust: 100%;
  font: 16px / 1.5 メイリオ, Meiryo, ryumin, Helvetica, Hiragino Kaku Gothic Pro, ヒラギノ角ゴ Pro W3, ＭＳ Ｐゴシック, sans-serif;
}

* html body {
  font-size: small;
}

body {
  height: 100%;
}

:first-child + html body {
  font-size: small;
}

img {
  vertical-align: top;
  border: 0;
}

h1, h2, h3, h4, h5, h6 {
  clear: both;
  font-size: 100%;
  font-weight: normal;
}

ul, dl, ol {
  text-indent: 0;
}

ul li {
  list-style: none;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

input {
  height: 36px;
}

* html input, * html textarea, * html select, :first-child + html + input, :first-child html + textarea, :first-child + html select {
  font-size: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: inherit;
}

th, td {
  text-align: left;
  vertical-align: top;
}

caption {
  text-align: left;
}

pre, code, kbd, samp, tt {
  font-family: monospace;
}

* html code, * html kbd, * html samp, * html tt {
  font-size: 100%;
  line-height: 100%;
}

:first-child + html pre, :first-child html + code, :first-child html + kbd, :first-child + html + samp, :first-child + html tt {
  font-size: 108%;
  line-height: 100%;
}

input, button, textarea, select {
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  margin: 0;
  padding: 0;
}

a:link, a:visited, a:active {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #c30;
  text-decoration: none;
}

a {
  outline: 0;
}

a img {
  border: none;
}

.hide, .hidden {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.red {
  color: #ab2727;
}

/*# sourceMappingURL=index.ffb74f81.css.map */
